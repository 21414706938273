import { Carousel, Container } from "react-bootstrap";
import styles from "./Home.module.css";

import homeImage from "../../../../img/home-image3.jpeg";
import About from "../../about-page/about/About";
import Activities from "../../about-page/activities/Activities";
import Purposes from "../../about-page/purposes/Purposes";

const Home = () => {
  return (
    <div id="home">
      <Carousel
        fade={true}
        className={styles.homeContainer}
        prevIcon={false}
        prevLabel={false}
        nextIcon={false}
        nextLabel={false}
        indicators={false}
      >
        <Carousel.Item>
          <img src={homeImage} className={styles.homeImage} alt="..." />
          <Carousel.Caption className={styles.captionCenter}>
            <h1 className={styles.carouselHeading}>
              Sukces jest sumą małych wysiłków, powtarzanych dzień po dniu.
            </h1>
            <p className={styles.carouselSubheading}>Robert Collier</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

        <div className="bg-dark">
          <About />
        </div>
        <div className="bg-light">
          <Purposes />
        </div>
        <div className="bg-dark">
          <Activities />
        </div>
    </div>
  );
};


export default Home;
