import { Container, Row, Col, Form, Button } from "react-bootstrap";

const Contact = () => {

  return (
    <Container id="contact" fluid>
      <Row>
        <Col xs={12} sm={6} md={4}>
          <div className="text-center mb-3">
            <h5>
            Fundacja Essa
            </h5>
            <ul className="list-unstyled">
              <li>KRS: 0001005899</li>
              <li>NIP: 5213995268</li>
            </ul>
          </div>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <div className="text-center mb-3">
            <h5>Dane kontaktowe</h5>
            <ul className="list-unstyled">
              <li>501 871 245</li>
              <li>fundacjaessa@protonmail.com</li>
            </ul>
          </div>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <div className="text-center mb-3">
          <h5>Konto bankowe</h5>
            <ul className="list-unstyled">
              <li>Santander Bank Polska</li>
              <li>31 1090 1694 0000 0001 5255 3444</li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
