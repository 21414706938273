import { Container, Row, Col } from "react-bootstrap";
import styles from "./About.module.css";


const About = () => {
  return (
    <div className={`app-container section ${styles.container}`}>
      <h2 className="text-center section-title color-light">O Fundacji</h2>
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={8}>
            <div className="bg-dark text-light">
              <p>
              Nasza misja skupia się na wspieraniu i rozwijaniu młodego pokolenia w ich działaniach i pomysłach. Wierzymy, że dzięki odpowiedniemu podejściu i wsparciu niemożliwe staje się możliwe.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
