import "./App.css";

import Navigation from "./app-modules/main-elements/nav/Navigation";
import Home from "./app-modules/app-pages/home-page/home/Home";
import Footer from "./app-modules/main-elements/footer/Footer";
import { Routes, Route } from "react-router-dom";


function App() {
  return (
    <div>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
