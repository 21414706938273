import { Navbar, Nav } from "react-bootstrap";
import styles from "./Navigation.module.css";
import logo from "../../../../src/img/logoNoBg.png";

const Navigation = () => {
  const onButtonClick = () => {
    const pdfUrl = "statut.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "statut.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Navbar sticky="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Nav.Link href="/#home">
        <img src={logo} className={styles.logo} alt="..." />
      </Nav.Link>

      <Navbar.Toggle aria-controls="responsive-navbar-nav" />

      <Navbar.Collapse id="responsive-navbar-nav" className="text-center">
        <Nav navbarScroll>
          {/* <Nav.Link href="/#news">Aktualności</Nav.Link> */}
          {/* <NavDropdown title="O Fundacji" id="collasible-nav-dropdown">
            <NavDropdown.Item
              className={` ${styles.dropdownItem}`}
              href="/about"
            >
              O Fundacji
            </NavDropdown.Item>
            <HashLink
              className={` ${styles.dropdownItem}`}
              to="/about#purposes"
            >
              <NavDropdown.Item
                href="/about#purposes"
                className={` ${styles.dropdownItem}`}
              >
                Cele
              </NavDropdown.Item>
            </HashLink>
            <HashLink
              className={` ${styles.dropdownItem}`}
              to="/about#activities"
            >
              <NavDropdown.Item
                className={` ${styles.dropdownItem}`}
                href="/about#activities"
              >
                Działania
              </NavDropdown.Item>
            </HashLink>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={onButtonClick}>Statut</NavDropdown.Item>
          </NavDropdown> */}
          {/* <Nav.Link href="/blog">Blog</Nav.Link>
          <Nav.Link eventKey={2} href="/gallery">
            Galeria
          </Nav.Link> */}
          <Nav.Link href="#about">O Fundacji</Nav.Link>
          <Nav.Link href="#purposes">Cele</Nav.Link>
          <Nav.Link href="#activities">Działania</Nav.Link>
          <Nav.Link href="#contact">Kontakt</Nav.Link>
          <Nav.Link onClick={onButtonClick}>Pobierz statut</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
