import { Container, Row, Col } from "react-bootstrap";

import handsIcon from "../../../../../src/img/icons/teamwork-together-icon.svg";
import healthyLifestyleIcon from "../../../../../src/img/icons/healthy-lifestyle-icon.svg";
import loanIcon from "../../../../../src/img/icons/loan-icon.svg";
import newspaperJobsIcon from "../../../../../src/img/icons/newspaper-jobs-icon.svg";

const Activities = () => {
  return (
    <Container className="app-container section" id="activities">
      <h2 className="section-title text-center color-light">Nasze działania</h2>
      <Row style={{ display: "flex", justifyContent: "space-around" }}>
        <Col
          md={2}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={handsIcon}
            alt="facebook"
            className="social-icon icon-light"
          />
          <p style={{ color: "white", textAlign: "center" }}>
            Organizowanie imprez o charakterze kulturalnym, rekreacyjnym oraz
            okolicznościowym
          </p>
        </Col>
        <Col
          md={2}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={healthyLifestyleIcon}
            alt="facebook"
            className="social-icon icon-light"
          />
          <p style={{ color: "white", textAlign: "center" }}>
            Promowanie i edukowanie młodzieży oraz ludzi młodych na temat
            zdrowia i chorób
          </p>
        </Col>
        <Col
          md={2}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={newspaperJobsIcon}
            alt="facebook"
            className="social-icon icon-light"
          />
          <p style={{ color: "white", textAlign: "center" }}>
            Wspieranie młodzieży i ludzi młodych na rynku pracy, poprzez
            możliwość organizowania lub pomocy w znalezieniu praktyk oraz staży
          </p>
        </Col>
      </Row>
      <Row style={{ display: "flex", justifyContent: "space-around" }}>
        <Col
          md={2}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={loanIcon}
            alt="facebook"
            className="social-icon icon-light"
          />
          <p style={{ color: "white", textAlign: "center" }}>
            Sponsorowanie stypendium sportowych oraz naukowych
          </p>
        </Col>
        <Col
          md={2}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={loanIcon}
            alt="facebook"
            className="social-icon icon-light"
          />
          <p style={{ color: "white", textAlign: "center" }}>
            Wspieranie finansowe badań naukowych wykonywanych przez studentów
            polskich uczelni
          </p>
        </Col>
        <Col
          md={2}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={loanIcon}
            alt="facebook"
            className="social-icon icon-light"
          />
          <p style={{ color: "white", textAlign: "center" }}>
            Pomoc finansowa na rozwój pomysłu, technologii oraz wprowadzanie lub
            modernizowanie innowacyjnych rozwiązań
          </p>
        </Col>
      </Row>
    </Container>
  );
};
export default Activities;
