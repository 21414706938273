/* eslint-disable jsx-a11y/anchor-is-valid */
import {Row} from "react-bootstrap";
import Contact from "./contact/Contact";
import facebookIcon from "../../../../src/img/icons/facebook-app-icon.svg";
import instagramIcon from "../../../../src/img/icons/ig-instagram-icon.svg";
import tiktokIcon from "../../../../src/img/icons/tiktok-app-icon.svg";
import twitterIcon from "../../../../src/img/icons/twitter-app-icon.svg";

const Footer = () => {
  return (
    <div className="bg-light section" style={{ color: "black" }}>
      {/* <p style={{ textAlign: "center" }}>Znajdziesz nas na</p> */}
      {/* <Row className="app-container" style={{ justifyContent: "center" }}>
        <a href="https://www.facebook.com">
          <img src={facebookIcon} alt="facebook" className="social-icon" />
        </a>

        <a href="https://www.instagram.com">
          <img src={instagramIcon} alt="instagram" className="social-icon" />
        </a>

        <a href="https://www.tiktok.com">
          <img src={tiktokIcon} alt="tiktok" className="social-icon" />
        </a>

        <a href="https://www.twitter.com">
          <img src={twitterIcon} alt="twitter" className="social-icon" />
        </a>
      </Row> */}
      <Contact />
    </div>
  );
};

export default Footer;
