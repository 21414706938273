import { Container, Row, Col } from "react-bootstrap";
import styles from "./Purposes.module.css";

import friendsGroupIcon from "../../../../../src/img/icons/friends-group-icon.svg";
import clientIcon from "../../../../../src/img/icons/client-icon.svg";
import technologyIcon from "../../../../../src/img/icons/technology-icon.svg";
import handProtestSignIcon from "../../../../../src/img/icons/hand-protest-sign-icon.svg";

const Offer = () => {
  const iconSize = "150px"; // Set the desired size for the icons

  return (
    <Container
      className={`app-container section ${styles.offer}`}
      id="purposes"
    >
      <h2 className="section-title text-center">Nasze cele</h2>
      <Row style={{ display: "flex", justifyContent: "space-around" }}>
        <Col
          md={2}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={friendsGroupIcon}
            alt="facebook"
            className="social-icon icon-dark"
            style={{ width: iconSize, height: iconSize }}
          />
          <p style={{ textAlign: "center" }}>
            Inicjowanie pomocy osobom wchodzącym w dorosłe życie takich jak
            nastolatkowie i ludzie młodzi wychowujących się w domach dziecka
          </p>
        </Col>
        <Col
          md={2}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={clientIcon}
            alt="facebook"
            className="social-icon icon-dark"
            style={{ width: iconSize, height: iconSize }}
          />
          <p style={{ textAlign: "center" }}>
            Inicjowanie pomocy osobom wchodzącym w dorosłe życie takich jak
            nastolatkowie i ludzie młodzi w trudnych sytuacjach życiowych
          </p>
        </Col>
        <Col
          md={2}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={technologyIcon}
            alt="facebook"
            className="social-icon icon-dark"
            style={{ width: iconSize, height: iconSize }}
          />
          <p style={{ textAlign: "center" }}>
            Prowadzenie działań w zakresie rozwoju wynalazczości, rozwoju
            technologii i innowacyjności wraz z wprowadzaniem nowych rozwiązań
            technicznych
          </p>
        </Col>
        <Col
          md={2}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={clientIcon}
            alt="facebook"
            className="social-icon icon-dark"
            style={{ width: iconSize, height: iconSize }}
          />
          <p style={{ textAlign: "center" }}>
            Zwiększenie atrakcyjności młodzieży i ludzi młodych na rynku pracy
          </p>
        </Col>
        <Col
          md={2}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={handProtestSignIcon}
            alt="facebook"
            className="social-icon icon-dark"
            style={{ width: iconSize, height: iconSize }}
          />
          <p style={{ textAlign: "center" }}>
            Motywowanie młodzieży i ludzi młodych w ich działaniach i pomysłach
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Offer;
